<!-- @format -->
<template>
  <button
    v-if="productId"
    class="c-btn-favoris"
    :class="{ 'c-btn-favoris--small': small }"
    :aria-label="$t('components.atoms.favoris.labelButton')"
    @click="clickOnBtn"
  >
    <FlatLoader v-if="load || initialLoad" />
    <template v-else>
      <template v-if="small">
        <WishA v-if="isInWishList" />
        <WishI v-else />
      </template>
      <template v-else>
        <WishProductA v-if="isInWishList" />
        <WishProductI v-else />
      </template>
    </template>
  </button>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import FlatLoader from '@/components/atoms/ui/loaders/FlatLoader.vue'
import WishA from '@/components/atoms/icons/WishA.vue'
import WishI from '@/components/atoms/icons/WishI.vue'
import WishProductA from '@/components/atoms/icons/WishProductA.vue'
import WishProductI from '@/components/atoms/icons/WishProductI.vue'

export default {
  components: {
    FlatLoader,
    WishA,
    WishI,
    WishProductA,
    WishProductI,
  },
  props: {
    small: {
      type: Boolean,
    },
    productId: {
      type: Number,
      default: null,
    },
    initialLoad: Boolean,
  },
  data() {
    return {
      load: false,
    }
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth?.loggedIn,
      wishList: (state) => state.wishlist && state.wishlist.products,
    }),
    isInWishList() {
      return this.wishList.some((wish) => wish.product === this.productId)
    },
  },
  methods: {
    ...mapActions({
      addWish: 'wishlist/addProduct',
      removeWish: 'wishlist/removeProduct',
      setProductToAdd: 'wishlist/setProductToAdd',
      setShowModalConnect: 'setShowModalConnect',
    }),
    clickOnBtn() {
      if (this.loggedIn) {
        if (this.isInWishList) {
          this.removeFromWishList()
        } else {
          this.addToWishList()
        }
      } else {
        this.$emit('setProductDataForTrackingAfterLogin')
        this.setProductToAdd(this.productId)
        this.setShowModalConnect(true)
      }
    },
    async removeFromWishList() {
      this.load = true
      const wish = this.wishList.find((wish) => wish.product === this.productId)
      await this.removeWish({
        wish,
        instance: this.$api,
      })
      this.$emit('removeToWishList')
      this.load = false
    },
    async addToWishList() {
      this.load = true
      await this.addWish({
        instance: this.$api,
        productId: this.productId,
      })
      this.$emit('addToWishList')
      this.load = false
    },
  },
}
</script>
<style lang="scss" scoped>
.c-btn-favoris {
  padding: 2.4rem 1.5rem;
  background-color: $grey-1400;

  svg,
  &::v-deep svg {
    display: block;
    width: 2.3rem;
    height: auto;
    pointer-events: none;
  }

  &--small {
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent;

    svg {
      width: 100%;
      filter: drop-shadow(0 0 1px $white);
    }
  }

  &--inactive {
    opacity: 0;
  }
}
</style>
